<template>
  <div class="noneHt">
    <div class="empty">
      <van-empty
        description="页面丢失"
        :image="require('@/assets/404/404.svg')"
      >
        <van-button
          type="info"
          class="bottom-button"
          @click="$router.replace('/')"
        >返回首页</van-button>
      </van-empty>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.noneHt {
  ::v-deep {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;

    .empty {
      height: 100%;
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;

        .bottom-button {
          width: 160px;
          height: 40px;
        }
      }
    }
  }
}
</style>
